import MaxWidth from '../MaxWidth';
import {BREAKPOINT} from '../../lib/styles';
import MedicallyReviewed from '../MedicallyReviewed';
import Byline from '../Byline';
import React from 'react';
import TableOfContentsModified from '../TableOfContents';
import HiddenOnDesktop from '../HiddenOnDesktop';
import styled from 'styled-components';
import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import PagePills from '../PagePills';

const TocStyles = styled.div`
    .toc__header {
        background: #fff !important;
    }
    button.toc {
        margin-bottom: 0;
    }
`;

const Title = (props) => {
    const {
        byline = {},
        lastUpdated = '',
        pageProps = {},
        showToc = true,
        showPagePills = true,
    } = props;

    return (
        <div style={props?.containerStyle} className="container">
            <MaxWidth>
                <HiddenOnDesktop>
                    <Breadcrumbs />
                </HiddenOnDesktop>
                {props?.dangerouslySetInnerHTML && (
                    <div className="title-content">
                        <MedicallyReviewed bylineData={props?.byline} />
                        <h1 dangerouslySetInnerHTML={props?.dangerouslySetInnerHTML} />
                    </div>
                )}
                {!props?.dangerouslySetInnerHTML && (
                    <div className="title-content">
                        <MedicallyReviewed bylineData={props?.byline} />

                        <h1 style={props?.style}>{props?.children}</h1>
                    </div>
                )}
                {byline && <Byline contributors={byline} lastModified={lastUpdated} />}
                {showPagePills && <PagePills />}

                {showToc && (
                    <HiddenOnDesktop>
                        <TocStyles>
                            <TableOfContentsModified />
                        </TocStyles>
                    </HiddenOnDesktop>
                )}
            </MaxWidth>
            <style jsx>{`
                .container {
                    padding: 40px 15px;
                    background-color: var(--tertiary-300);
                    position: relative;
                    margin-bottom: 40px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .container {
                        padding: 80px 0;
                        margin-bottom: 80px;
                    }
                }
                .title-content {
                    position: relative;
                }
            `}</style>
        </div>
    );
};

export default Title;
